const URL_POT_OPTIONS = "/promotion-planning/promo-optis/promo-options";
const URL_POT_PARAMETERS = "/promotion-planning/promo-optis/parameters";
const URL_POT_PLAN = "/promotion-planning/common/plans";
const URL_POT = "/promotion-planning/promo-optis";
const URL_POT_CHART_YEAR = "/promotion-planning/common/chart-year";
const URL_POT_COMPARISON = "/promotion-planning/promo-optis/comparisons";
const URL_POT_METRIX = "/promotion-planning/common/metrix-list";
const URL_POT_PLAN_METADATA = "/promotion-planning/common/plan-info";
const URL_PP_APPROVE = "/promotion-planning/promo-staging/approve";
const URL_PP_DELETE = "/promotion-planning/common/delete-plan/";
export { URL_PP_APPROVE as U, URL_POT_CHART_YEAR as a, URL_POT as b, URL_POT_COMPARISON as c, URL_POT_METRIX as d, URL_POT_OPTIONS as e, URL_PP_DELETE as f, URL_POT_PLAN_METADATA as g, URL_POT_PLAN as h, URL_POT_PARAMETERS as i };
